import React from 'react'
import Form from "./Forms"
const Home = () => {
  return (
    <div className='bg-dark'>
        <Form/>
    </div>
  )
}

export default Home